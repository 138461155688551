import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { discountAmount } from '@lib/price';
import {
  PromotionBannerInfoWrapper,
  PromotionMainBannerContainer,
  PromotionMainBannerInnerBorder,
  PromotionProductBrandText,
  PromotionProductBrandWrapper,
  PromotionProductDiscountRateText,
  PromotionProductPriceText,
  PromotionProductPriceWrapper,
  PromotionProductTag,
  PromotionProductTitleText,
  PromotionRemainingTimer,
} from '@components/_new_components/Pages/main/PromotionBanner/PromotionMainBanner.styles';
import { PromotionMainBannerSkeleton } from '@components/_new_components/Pages/main/PromotionBanner/PromotionBanner.styles';
import { CustomImage } from '@components/ui/Image';
import {
  Brand_Output_Brand_Vip_Type,
  Language_Code,
  Products_Sort_Criterion,
  Products_Sort_Key,
  Promotion_Concept_Codes,
  useProductsQuery,
} from '../../../../../src/generated/graphql';
import AconOnlyIcon from '../../../../../src/assets/icons/productCard/badge/AconOnlyIcon';
import VipIcon from '../../../../../src/assets/icons/productCard/badge/VipIcon';

interface Props {
  activePage: number;
  isOpenrun: boolean;
  onMouseOver: () => void;
  onMouseOut: () => void;
}

export default function PromotionMainBanner({ activePage, isOpenrun, onMouseOver, onMouseOut }: Props) {
  const { i18n } = useTranslation();
  const [remainingTime, setRemainingTime] = React.useState(null);

  const { data, loading, error } = useProductsQuery({
    variables: {
      language: i18n.language.toUpperCase() as Language_Code,
      pagination: {
        page: activePage,
        limit: 1,
      },
      condition: {
        isPromoted: true,
        isMatureContent: false,
        salePriceAbove: 1,
        promotionConceptCode: isOpenrun ? Promotion_Concept_Codes.Openrun : Promotion_Concept_Codes.Flashsale,
      },
      sort: {
        key: Products_Sort_Key.Id,
        criterion: Products_Sort_Criterion.Asc,
      },
    },
    skip: !activePage,
  });
  const product = (data?.products?.data || [])[0];
  const isLoading = loading || error;

  React.useEffect(() => {
    if (!product || !product?.promotionInfo?.until) return;
    setRemainingTime(new Date(product.promotionInfo.until));
  }, [product]);

  if (isLoading || !product) return <PromotionMainBannerSkeleton />;

  return (
    <Link href={`/product/${product.id}`} prefetch={false}>
      <PromotionMainBannerContainer onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
        <CustomImage src={product.imageInfo.main.url} alt={'test'} quality={50} sizes={'594px'} style={{ objectFit: 'cover' }} />

        {remainingTime && <PromotionRemainingTimer remainingTime={remainingTime} isPromotion />}

        <PromotionBannerInfoWrapper>
          <PromotionProductBrandWrapper>
            <PromotionProductBrandText>{product.brandInfo.name}</PromotionProductBrandText>

            {product.brandInfo?.isAcon3DExclusive && <AconOnlyIcon />}
            {product.brandInfo?.vipInfo === Brand_Output_Brand_Vip_Type.Vip && <VipIcon />}
          </PromotionProductBrandWrapper>
          <PromotionProductTitleText>{product.title}</PromotionProductTitleText>

          <PromotionProductPriceWrapper>
            {discountAmount(product.priceInfo.price, product.priceInfo.salePrice) > 0 && (
              <PromotionProductDiscountRateText>{discountAmount(product.priceInfo.price, product.priceInfo.salePrice)}%</PromotionProductDiscountRateText>
            )}
            <PromotionProductPriceText amount={product.priceInfo.salePrice} />
          </PromotionProductPriceWrapper>

          <PromotionProductTag tags={(product?.tags || []).slice(0, 2).map((item) => item.name)} isDark={true} />
        </PromotionBannerInfoWrapper>

        <PromotionMainBannerInnerBorder />
      </PromotionMainBannerContainer>
    </Link>
  );
}
