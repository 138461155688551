import styled from '@emotion/styled';
import RemainingTimer from '@components/_new_components/Common/Timer/RemainingTimer';
import PriceText from '@components/_new_components/Common/Text/PriceText';
import { AmountText, PriceUnitText } from '@components/_new_components/Common/Text/PriceText.styles';
import Tags from '@components/_new_components/Common/ProductCard/Tag/Tags';
import { Tag } from '@components/_new_components/Common/ProductCard/Tag/Tags.styles';
import { RemainingTimerDateText, RemainingTimerLabelText } from '@components/_new_components/Common/Timer/RemainingTimer.styles';

export const PromotionMainBannerContainer = styled.div`
  width: 100%;
  height: 276px;
  overflow: hidden;
  border-radius: 16px;
  position: relative;

  ${(props) => props.theme.breakpoints.down('dt_1720')} {
    height: 248px;
  }

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    height: 236px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    height: 190px;
    border-radius: 12px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    height: 170px;
  }
`;

export const PromotionMainBannerInnerBorder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1.5px solid rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  pointer-events: none;

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    border-radius: 12px;
  }
`;

export const PromotionBannerInfoWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 180px;
  bottom: 0;
  left: 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) 100%);

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    height: 160px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    height: 120px;
  }
`;

export const PromotionProductBrandWrapper = styled.div`
  display: flex;
  gap: 3px;
  margin-bottom: 3px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    margin-bottom: 2px;
  }
`;

export const PromotionProductBrandText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  opacity: 0.6;
  color: #e7e7e8;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    font-size: 11px;
    line-height: 16px;
  }
`;

export const PromotionProductTitleText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #e7e7e8;

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    font-size: 13px;
    line-height: 18px;
  }
`;

export const PromotionProductPriceWrapper = styled.div`
  display: flex;
  gap: 6px;
  margin-bottom: 4px;

  overflow: hidden;
`;

export const PromotionProductDiscountRateText = styled.div`
  color: #ff5c00;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    font-size: 15px;
    line-height: 20px;
  }
`;

export const PromotionProductPriceText = styled(PriceText)`
  & > ${PriceUnitText}, ${AmountText} {
    color: white;

    ${(props) => props.theme.breakpoints.down('st_600')} {
      font-size: 15px;
      line-height: 20px;
    }
  }

  & > ${PriceUnitText} {
    font-weight: 800;
    font-size: 16px;
    padding-bottom: 1px;
    padding-left: 1px;
    ${(props) => props.theme.breakpoints.down('st_600')} {
      font-size: 13px;
      height: 21px;
    }
  }
`;

export const PromotionRemainingTimer = styled(RemainingTimer)`
  position: absolute;
  left: 10px;
  top: 10px;

  font-size: 14px;
  column-gap: 6px !important;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    padding: 4px 6px 4px 7px;

    & ${RemainingTimerLabelText} {
      font-size: 12px;
    }

    & ${RemainingTimerDateText} {
      font-size: 13px;
    }
  }
`;

export const PromotionProductTag = styled(Tags)`
  margin-top: 0;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    & ${Tag} {
      padding: 4px 6px 4px 6px;
      font-size: 10px;
      max-height: 17px;
    }
  }
`;
